.hero-section {
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  position: relative;
  align-items: center;
  justify-content:left;
  text-align: left;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.hero-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-content {
  z-index: 1;
  background-color: transparent;
  border-radius: 10px;
  margin: 0;
  width: 100%;
  height: auto;
}

.hero-content p {
  margin-bottom: 25px;
  font-size: 18px;
}

.hero-content h1 {
  margin-top: 10px;
  margin-bottom: 25px;
  font-size: 38px;
  font-weight: 700;
  color: #ffffff;
  padding: 0;
}

.hero-cta {
  background-color: #fc0a7e;
  color: #ffffff;
  text-decoration: none;
  padding: 20px 40px;
  border-radius: 3px;
  font-size: 18px;
  font-weight: 600;
  transition: background-color 0.5s ease-in-out, color 0.2s ease-in-out;
}

.hero-cta:hover {
  background-color: #ffffff;
  color:#443c38;
  text-decoration: none;
}

.hero-content {
  opacity: 1;
  transform: translateY(40px);
  transition: opacity 1.5s ease-out, transform 1.5s ease-out;
}
.hero-content.visible {
  opacity: 1;
  transform: translateY(0);
}

@media (min-width: 1025px) {
  .hero-content {
    padding-left: 20vw;
    padding-right: 20vh;
    padding-top: 0;
    padding-bottom: 0;
  }
  .hero-content h1 {
    font-size: 44px;
  }

  .hero-cta {
    font-size: 20px;
  }
}

@media ((min-width: 769px) and (max-width: 1024px)) {
  .hero-content {
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 0;
    padding-bottom: 0;
    max-width: 500px;

  }
}

@media (max-width: 768px) {
  .hero-content {
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 0;
    padding-bottom: 0;
    max-width: 400px;
  }
}

@media (max-width: 480px) {
  .hero-content {
    padding-left: 2rem;
    padding-right: 3rem;
    padding-top: 0;
    padding-bottom: 0;
    max-width: 350px;
  }
}