.sign-up-form-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center elements horizontally */
  justify-content: center; /* Center elements vertically */
  height: 100vh - 20px; /* Full height for vertical centering */
  padding: 20px;
}

.sign-up-form-container h2 {
  display: flex;
  margin-top: 0px;
  justify-content: center;
  font-size: 24px;
  color: #513A2C;
}

.sign-up-form {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center form elements horizontally */
  gap: 15px; /* Space between form elements */
  width: 100%;
  max-width: 400px;
  border: 1px solid #513A2C;
  border-radius: 8px;
  padding: 40px;
}

.sign-up-form input, .sign-up-form button {
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 5px;
  font-size: 16px;
}

.sign-up-form button {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  background-color: #4a90e2;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.sign-up-form button:hover {
  background-color: #357ABD; /* Darker shade on hover */
}

.sign-up-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-top: 0px;
}

.sign-up-button-container button {
  flex: 1;
  max-width: 130px;
  padding: 6px 0;
  margin-top: 10px;
  font-size: 12px;
  background-color: #93AFAE;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  text-align: center;
}


.back-to-signin-container button{
  flex: 1;
  max-width: 130px;
  padding: 6px 10px;
  margin-top: 10px;
  font-size: 12px;
  background-color: #93AFAE;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  text-align: center;
}