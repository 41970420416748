body, html {
  font-family: 'Gothic A1';
  box-sizing: border-box;
}

.qr-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #ffffff;
}

.qr-page {
  text-align: center;
  display: flex;
  width: auto;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content: center;
  background: white;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.qr-page h2 {
  font-size: 18px;
  font-weight: 600;
}

.qr-page p {
  font-size: 16px;
  font-weight: 400;
}

.qr-page button {
  margin: 0 auto;
  background-color: #007bff;
  color: white;
  border: none;
  font-size: 14px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.qr-page button:hover {
  background-color: #0056b3;
}
