/* General Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Gothic A1';
  box-sizing: border-box;
}

#features-moreinfo {
  padding: 20px;
  margin-top: 86px;
  background-color: #fff;
}

/* Enter Event Code Section */
.enter-event-code {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 2rem auto;
  text-align: center;
}

.enter-event-code h2 {
  margin-bottom: 1rem;
  font-size: 20px;
  color: #444;
}

.enter-event-code form {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.enter-event-code input {
  padding: 10px;
  font-size: 14px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.enter-event-code form .event-code-submit-button {
  padding: 0px;
  font-size: 14px;
  background-color: transparent;
  display: flex;
  align-items: center;
  color: #93afae;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.enter-event-code form .event-code-submit-button:hover {
  color: #5e807f;
}

.error-message {
  color: red;
  font-size: 0.9rem;
}

/* Tabs Section */
.tabs-itinerary {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 40px;
  margin-top: 20px;
  max-width: 100%;
} 

.key-locations-container h1,
.key-people-container h1,
.itinerary-container h1 {
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #6f8c8b;
}

.tab-button-itinerary {
  padding: 5px;
  background-color: transparent;
  color: #6f8c8b;
  border: none;
  cursor: pointer;
  transition: border-bottom 0.3s ease;
  width: 15%;
}

.tab-button-itinerary .material-icons-outlined,
.tab-button-itinerary .material-icons {
  color: #6f8c8b;
  font-size: 30px;
}

.tab-button-itinerary:hover,
.tab-button-itinerary.active{
  border-bottom: 3px solid #6f8c8b;
}

/* Itinerary Content */
.itinerary-page {
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 800px;
}

.itinerary-container .material-icons-outlined,
.key-locations-container .material-icons-outlined,
.key-people-container .material-icons-outlined {
  color: #6f8c8b;
  font-size: 26px;
  padding: 5px;
}

.day-section {
  margin-bottom: 20px;
}

.day-section h2 {
  font-size: 18px;
  font-weight: 600;
  color: #6f8c8b;
  margin-bottom: 10px;
  margin-top: 40px;
  border-bottom: 1px solid #6f8c8b;
  padding-bottom: 5px;
}

/* Cards */
.key-people-container, .key-locations-container {
  display: flex;
  flex-direction: column;
  /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
  gap: 20px;
  align-items: start;
}

.events-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  gap: 20px;
  align-items: start;
}

.event-card, .person-card, .location-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.event-details > div, .key-people-container > div, .person-card > div, .location-card > div, .key-locations-container > div {
  display: flex;
  align-items: start;
  margin-top: 5px;
  gap: 0 5px;
  width: 100%;
}

.event-time, .key-people-container span, .key-locations-container span {
  color: #6f8c8b;
  display: flex;
  align-items: center;
  gap: 5px;
}

.event-time {
  margin-bottom: -10px;
}

.event-details div,
.person-card div, 
.location-card div {
  display: flex;
  align-items: center;
  width: 100%;
  color: #6f8c8b;
}

/* Inputs */
.edit-input-field {
  width: 100%;
}

.editable-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

/* Buttons */
.save-button {
  display: block;
  padding: 10px 50px;
  font-size: 14px;
  background-color: #93AFAE;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0px auto;
  transition: background-color 0.3s ease;
}

.itinerary-container .save-button {
  margin: 0px auto;
}

.save-button:hover {
  background-color: #335251;
}

.save-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.add-button {
  display: block;
  padding: 10px;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #4f6e6c;
  border-radius: 25px;
  cursor: pointer;
  transition: border 0.3s ease;
  margin: 0px auto;  
}

.add-button .material-icons-outlined {
  color: #4f6e6c;
  font-size: 24px; /* Icon size */
  width: 24px; /* Match font-size for a square shape */
  height: 24px; /* Match font-size for a square shape */
  display: inline-flex; /* Aligns better in flex layouts */
  justify-content: center; /* Center icon content horizontally */
  align-items: center; /* Center icon content vertically */
}

.material-icons-outlined {
  line-height: 1; /* Ensures the icon is not stretched */
  display: inline-block; /* Prevents inline text issues */
  vertical-align: middle;
}


.itinerary-container .add-button {
  margin: 20px auto;
}

.itinerary-container {
  width: auto;
  height: auto;
}

.add-button:hover {
  scale:calc(1.02);
}

.add-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.delete-button {
  display: block;
  margin: 0px auto;
  font-size: 14px;
  background-color: transparent;
  color: #ff6b6b;
  border: 1px solid #ff6b6b;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.key-locations-container .delete-button, .key-people-container .delete-button {
  margin: 10px auto 0px auto;
}

.delete-button:hover {
  background-color: #e63946;
  color: white;
}
