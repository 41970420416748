/* Overall page background (optional) */
body {
  background-color: #fcf9f4; 
  margin: 0;
  font-family: 'Gothic A1';
  color: black;
}

/* Container for the product layout */
.product-page {
  display: flex;
  flex-wrap: wrap; /* allows responsive behavior */
  max-width: 1200px;
  margin: 2rem auto;
  gap: 1rem;
  padding: 2rem;
  margin-top: 86px;
}

/* Left side: product image/gallery */
.product-gallery {
  flex: 1 1 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-product-image {
  width: 60%;
  max-width: 400px;
}

/* Right side: product info */
.product-details {
  flex: 1 1 400px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Title */
.product-title {
  font-size: 1.8rem;
  margin: 0 0 0.5rem;
  font-weight: bold;
  color: black;
}

/* Price & rating container */
.price-rating {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Price text */
.product-price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fc0a7e;
}

/* Rating display */
.star-rating {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.review-count {
  font-size: 0.9rem;
}

/* Form for product options */
.product-options {
  display: flex;
  flex-direction: column;
}

.product-options label {
  font-weight: 600;
  margin-bottom: 0.25rem;
  margin-top: 1.25rem;
}

.product-options select {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

/* Buttons (Add to Cart & Buy Now) */
.action-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.2s;
}

/* 'Add to Cart' button styles */
.add-to-cart {
  background-color: #a8a8a8; 
  color: #fff;
}

.add-to-cart:hover {
  background-color: #636363; 
  color: #fff;
}

/* 'Buy Now' button styles */
.buy-now {
  background-color: #fc0a7e;
  color: #fff;
}

.buy-now:hover {
  background-color: #c10a62;
  color: #fff;
}

/* "What's Included" box */
.included-features {
  background-color: #fff;
  padding: 1rem;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.05);
}

.included-features h3 {
  margin-top: 0;
}

.included-features ul {
  margin: 0;
  padding-inline-start: 1.25rem;
}
