/* General Section Styling */
#about {
  padding: 2rem;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 86px;
}

#about h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

#about p {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #333;
}
