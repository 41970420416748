body, html {
  font-family: 'Gothic A1';
  box-sizing: border-box;
}

.prompts-page {
  padding: 0px;
  background-color: #ffffff;
  /* border: 1px solid #ddd; */
  /* border-radius: 8px; */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  margin-bottom: 0px;
}

.prompt-upload {
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prompt-upload h2 {
  font-size: 18px;
  color: #4f6e6c;
}

.prompt-upload .form-group {
  display: flex;
  flex-direction: column;;
  width: 100%;
  max-width: 400px;
}

.prompt-upload textarea {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fafafa;
  font-size: 14px;
}

.wide-textarea {
  width: 100%; /* or any desired width, e.g., 500px */
  max-width: 100%; /* Optional, to limit width in responsive layouts */
  box-sizing: border-box; /* Ensures padding/border doesn't affect width */
}


.custom-datepicker::placeholder {
  font-size: 14px;
}

.custom-datepicker {
  background-color: #fafafa;
}

.prompt-upload button {
  padding: 10px 20px;
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: -10px;
  background-color: #93AFAE;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.prompt-upload button:hover {
  background-color: #335251;
}

.error-message {
  color: red;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;

}

.success-message {
  color: green;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;
}

.date-filter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.date-filter-input {
  width: 100%;
  max-width: 120px;
  padding: 8px;
  font-size: 14px;
  box-sizing: border-box;
}

.date-filter label {
  margin-bottom: 5px;
  font-size: 14px;
  color: black; /* Adjust the color as needed */
}

.date-filter-dropdown {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: #fff;
  appearance: none;
}

.date-filter-dropdown:focus {
  border-color: #93AFAE; /* Adjust the color for focus state */
  outline: none;
}

.search-input {
  padding: 6px;
  border: 1px solid #93AFAE;
  border-radius: 4px;
  width: 100%;
  max-width: 200px;
  font-size: 14px;
}


.prompts-table .delete-guest-button {
  background-color: transparent;
  color: black;
  border: none;
  cursor: pointer;
  padding: 0px;
  transition: background-color 0.3s;
}

.prompts-table .delete-guest-button:active {
  transform: scale(0.9);
}

.prompts-table .delete-guest-button:hover {
  background-color: transparent;
}

.prompts-table .delete-guest-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prompts-table .delete-guest-button .material-icons {
  font-size: 24px;
  padding: 5px;
}

/* Prompt Table */
.prompts-table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.prompts-table th,
.prompts-table td {
  padding: 10px;
  width:auto;
  border: 1px solid #ddd;
  text-align: left;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.prompts-table th {
  background-color: #f4f4f4;
  font-size: 16px;
  font-weight: 600;
  color: black;
  text-align: center;
}

.prompts-table td {
  font-size: 14px;
  white-space: normal;
}

.prompts-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.prompts-table .narrow-column {
  width: 10px;
  text-align: left;
  justify-content: center;
  background-color: transparent;
  border: none;
}

.prompts-table .narrow-column:hover {
  background-color: transparent;
  border: none;
}

.prompts-table .narrowish-column {
  width: 20%;
  text-align: center;
  padding: 4px;
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap:normal;
}

.prompts-table .narrower-column {
  text-align: center;
  width:30%;
  justify-content: center;
  box-sizing: border-box;
  overflow-wrap:normal;
}

/* Dark Mode */
/* @media (prefers-color-scheme: dark) {
  .event-hub-page {
    background-color: #121212;
  }

  .tabs-itinerary {
    background-color: #1e1e1e;
  }

  .tab-button-itinerary {
    background-color: #333;
    color: white;
  }

  .tab-button-itinerary.active {
    background-color: #6F8C8B;
  }

  .sms-form-container,
  .prompt-upload,
  .scheduled-prompts {
    background-color: #1e1e1e;
    border: 1px solid #444;
  }

  .sms-form input,
  .sms-form textarea,
  .prompt-upload textarea {
    background-color: #333;
    color: white;
    border: 1px solid #555;
  }

  .prompts-table th,
  .prompts-table td {
    border: 1px solid #444;
  }

  .prompts-table th {
    background-color: #222;
  }

  .prompts-table tr:nth-child(even) {
    background-color: #333;
  }

  .prompts-table tr:hover {
    background-color: #444;
  }

  h1, h2 {
    color: white;
  }
} */
