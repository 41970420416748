.event-hub-page {
  padding: 0px;
  background-color: #ffffff;
  /* border: 1px solid #ddd; */
  /* border-radius: 8px; */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  margin-bottom: 10px;
}

.add-guest-form {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.add-guest-form h2{
  font-size: 18px;
  color: #4f6e6c;
  margin-bottom: 0px;
}

.add-guest-form form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  width: auto;
  background-color: #fff;
}

.add-guest-form input {
  max-width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  background-color: #fafafa;
}

.csv-upload {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%
}

.csv-upload h2{
  font-size: 18px;
  color: #4f6e6c;
  margin-bottom: 0px;
}

.custom-file-button {
  padding: 10px;
  font-size: 14px;
  margin-bottom: 0px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-file-button:hover {
  background-color: #335251;
}

.save-changes-button, .add-guest-form form button {
  padding: 10px;
  font-size: 14px;
  margin-bottom: -20px;
  background-color: #93AFAE;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
}

.save-changes-button:hover, .add-guest-form form button:hover {
  background-color: #335251;
}

/* .save-changes-button:disabled, .add-guest-form form button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
} */

/* Prompt Table */
.prompts-table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed;
}

.prompts-table th,
.prompts-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.prompts-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  color: Black;
  text-align: center;
}

.prompts-table td {
  max-width: 200px;
  font-size: 14px;
  white-space: normal;
}

.prompts-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* .prompts-table tr:hover {
  background-color: #f1f1f1;
} */

/* Error & Success Messages */
.error-message {
  color: red;
  font-size: 16px;
  text-align: center;
}

.success-message {
  color: green;
  font-size: 16px;
  text-align: center;
}

.clear-filter-button {
  display: flex;
  align-items: center;
  background-color: #6F8C8B;
  color: white;
  border: none;
  margin-top: 5px;
  border-radius: 4px;
}

.search-input {
  padding: 6px;
  border: 1px solid #93AFAE;
  border-radius: 4px;
  width: 100%;
  max-width: 200px;
  font-size: 14px;
}

.search-input:focus {
  outline: none;
  border-color: #4a90e2; /* Change color to indicate focus */
}

.editable-cell {
  width: 100%;
  padding: 4px;
  box-sizing: border-box;
  border: 1px solid transparent;
  outline: none;
  background-color: #f9f9f9;
}

.editable-cell:focus {
  border: 1px solid #4a90e2;
  background-color: #fff;
}

.prompts-table .delete-guest-button {
  background-color: transparent;
  color: #513A2C;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: background-color 0.3s;
}

.prompts-table .delete-guest-button:active {
  transform: scale(0.9);
}

.prompts-table .delete-guest-button:hover {
  background-color: transparent;
}

.prompts-table .delete-guest-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.prompts-table .delete-guest-button .material-icons {
  font-size: 20px;
}

.prompts-table .narrow-column {
  width: 20px;
  text-align: left;
  justify-content: center;
  background-color: transparent;
  border: none;
}

.prompts-table .narrow-column:hover {
  background-color: transparent;
  border: none;
}