/* General FAQ styles */
.faq-wrap {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  color: #fff;
}

.faq-wrap h1 {
  font-family: 'Gothic A1';
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  color: black;
}

/* Mobile-friendly single-column layout */
.faq-grid {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* FAQ cards */
.faq-card {
  background-color: #fff;
  border: 1px solid #6b6b6b;
  border-radius: 10px;
  padding: 20px;
  transition: all 0.3s ease;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.faq-card:hover {
  color: #6f8c8b;
}

.faq-card.open {
  background-color: #fff;
}

/* FAQ header */
.faq-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
  color: black;
}

.faq-toggle {
  font-size: 10px;
  color: #fc0a7e;
  transition: transform 0.3s ease;
}

.faq-card.open .faq-toggle {
  color: #fc0a7e;
}

/* FAQ answer */
.faq-answer {
  font-size: 16px;
  font-weight: 400;
  color: #6b6b6b;
  margin-top: 10px;
  line-height: 1.6;
  text-align: left;
}

/* Desktop-specific styles (screen widths above 1024px) */
@media (min-width: 1024px) {
  .faq-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    gap: 20px;
    align-items: start;
  }

  .faq-card {
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .faq-card:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
  }

  .faq-header {
    font-size: 20px;
  }

  .faq-answer {
    font-size: 18px;
  }
}
